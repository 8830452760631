import React, { useEffect } from "react";
import BaseComponent from "../base/Base_component";
import "./Service_component.scss";

function ServicesComponent() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <React.Fragment>
      <BaseComponent>
        
      </BaseComponent>
    </React.Fragment>
  );
}

export default ServicesComponent;
